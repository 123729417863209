import React, { Component } from 'react';

import { ReactComponent as Favicon } from './../assets/img/favicon.svg';

class Loading extends Component{
  constructor(){
    super();
    
    this.state = {
      // focus: false
    }

    // this.changeFocus = this.changeFocus.bind(this);
    
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){}
  render(){
    return(
      <div className={`loading ${this.props.onPage ? 'page' : ''} ${this.props.onSection ? 'section' : ''}`}>
        <div>
          <Favicon />
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Loading;