import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBW3_O5nq520tv6G1rxKsHtxot5JVj2KYQ",
  authDomain: "asa-juragan.firebaseapp.com",
  projectId: "asa-juragan",
  storageBucket: "asa-juragan.appspot.com",
  messagingSenderId: "489955169444",
  appId: "1:489955169444:web:8c4598862b7ebb6c580231",
  measurementId: "G-G3QK84J55D"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const fn = getFunctions(firebaseApp);

export default firebaseApp;