import React, { Component } from 'react';
import Select from 'react-select';
// import { NumericFormat } from 'react-number-format';

class SelectBox extends Component{
  constructor(){
    super();
    
    this.state = {
      focus: false
    }

    this.selectFocusIn = this.selectFocusIn.bind(this);
    this.selectFocusOut = this.selectFocusOut.bind(this);
    // this.selectMenu = this.selectMenu.bind(this);
  }

  componentDidMount(){
    this.selectFocusIn();
    this.selectFocusOut();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.value !== prevProps.value){
      this.selectFocusIn();
      this.selectFocusOut();
    }
  }

  componentWillUnmount(){}

  selectFocusIn(){
    this.setState({ focus: true });
  }

  selectFocusOut(){
    if(!this.props.value){
      this.setState({ focus: false });
    }
  }

  // selectMenu(){
  //   this.selectFocusIn();
  //   this.selectFocusOut();
  // }

  render(){
    return(
      <>
        {this.props.placeholder ? (
          <label className={`text ${this.state.focus ? 'focus' : ''}`}>
            {this.props.placeholder} {this.props.required ? (<span>*</span>) : ''}
          </label>
        ) : ''}
        {/* {this.props.labelLeft ? (
          <div className="label-left">
            {this.props.labelLeft}
          </div>
        ) : ''} */}
        <Select
          // placeholder={this.props.placeholder}
          placeholder=""
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          // onFocus={this.selectFocus}
          onBlur={this.selectFocusOut}
          onMenuOpen={this.selectFocusIn}
          // onMenuClose={this.selectFocusOut}
          isClearable={this.props.isClearable}
          className="react-select"
          classNamePrefix="react-select"
          noOptionsMessage={() => 'Tidak ada pilihan' + (this.props.placeholder ? ' ' + this.props.placeholder : '.')}
          menuPortalTarget={document.querySelector('body')}
          formatOptionLabel={this.props.formatOptionLabel}
          options={this.props.options}
          isMulti={this.props.isMulti}
          isOptionDisabled={(option) => option.disabled}
          isDisabled={this.props.disabled}
        />
      </>
    );
  }
}

export default SelectBox;